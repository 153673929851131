.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal-body {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  max-width: 90%;
  transform: translate(-50%, -50%);
  background-color: #10141f;
  color: #fff;
  border: 0;
  border-radius: 10px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
  padding: 0;
  text-align: center;
}

.modal-caption {
  font-size: 30px;
  font-weight: bold;
  border-bottom: 1px solid rgba(255, 255, 255, .06);
  padding: 18px 24px;
}

.modal-content {
  padding: 30px 24px;
}

.modal-content img {
  width: 90px;
  height: 90px;
  margin: auto;
}

.modal-content h2 {
  margin-top: 25px;
  font-size: 20px;
  font-weight: bold;
}

.modal-content p {
  font-size: 16px;
}